//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TestWork',
  data () {
    return {
      visible: false,
      visible2: false
    }
  },
  created () {
    this.form = this.$form.createForm(this)
    this.form2 = this.$form.createForm(this)
  },
  methods: {
    handleCloseCurrentTab () {
      this.$multiTab.closeCurrentPage() // or this.$multiTab.close()
    },
    handleOpenTab () {
      this.$multiTab.open('/features/task')
    },

    handleOpenLoading () {
      this.$nextTick(function () {
        console.log('this', this)
        console.log('this.$refs.tInput', this.$refs.tInput)
      })
      this.$loading.show()
      setTimeout(() => {
        this.$loading.hide()
      }, 5000)
    },
    handleOpenLoadingCustomTip () {
      this.$loading.show({ tip: '自定義提示語' })
      setTimeout(() => {
        this.$loading.hide()
      }, 5000)
    },

    // confirm
    confirm (e) {
      e.stopPropagation()
      const { path } = this.$route
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$multiTab.rename(path, values.tabName)
          this.visible = false
        }
      })
    },
    cancel () {
      this.visible = false
    },
    confirm2 (e) {
      e.stopPropagation()
      this.form2.validateFields((err, values) => {
        if (!err) {
          this.$multiTab.rename(values.tabKey, values.tabName)
          this.visible2 = false
        }
      })
    }
  }
}
